<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">物业管理列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editProperty">添加物业</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input label="物业名称" v-model="pageParam.params.name" />
                <le-input label="银行卡号" v-model="pageParam.params.accountNumber" />
            </le-search-form>
            <le-pagview ref="propertyListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.propertyList" :pageSizeDefault='10'>
                <el-table ref="propertyList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="province" label="物业名称" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="银行卡号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.accountNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="银行名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.bankName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="支行信息" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.bankBranchName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="票据类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.billType | initDic(billTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="付款周期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>每{{ row.alertCycle | initDic(timeCycleDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="提醒日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>每{{ row.alertCycle | initDic(timeCycleDic) }} {{ row.alertDate ? row.alertDate.split('-')[2]:'-' }}日</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="到期日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>每{{ row.alertCycle | initDic(timeCycleDic) }} {{ row.dueDate ? row.dueDate.split('-')[2]:'-' }}日</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="editProperty(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.propertyCompanyPage,
                    method: "post",
                    params: {
                        name: '',
                        accountNumber: '',
                    },
                    freshCtrl: 1,
                },
                timeCycleDic: [],
                billTypeDic: []
            }
        },
        created () {
            this.$getDic('timeCycle','select').then(res=>{ this.timeCycleDic = res; })
            this.$getDic('billType','select').then(res=>{ this.billTypeDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            handlerRest() {
                this.pageParam.params = {
                    name: '',
                    accountNumber: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['propertyListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            editProperty (datas) {
                this.$router.push({
                    path: '/electric/electric-property-edit',
                    query:{
                        id: datas?datas.id:'',
                    }
                })
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 105px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>